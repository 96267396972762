import React from "react";
import { graphql } from "gatsby";
import { Container, Grid } from "@material-ui/core";

import Layout from "../components/theme/layout";
import SEO from "../components/theme/seo";
import Intro from "../components/website/Intro";
import BlueSection from "../components/website/BlueSection";
import Parallax from "../components/website/Parallax";
import OneCol from "../components/website/one-col";
import Members from "../components/members/member";

import Footer from "../images/about/about-footer-cta.jpg";
import blueBG from "../images/about/about-background.svg";
import AboutHero from "../images/about/about-hero.jpeg";

export default function About({ data }) {
  return (
    <Layout>
      <SEO title="About" />
      {data.allStrapiAbout.edges.map(({ node }) => (
        <React.Fragment key={node.id}>
          <Intro
            key={node.about_intro.id}
            title={node.about_intro.intro_title}
            text={node.about_intro.intro_description}
            img={AboutHero}
            color={node.about_intro.intro_color}
          />
          <BlueSection
            key={node.about_bluesection.id}
            title={node.about_bluesection.Bluesection_title}
            description={node.about_bluesection.bluesection_title_description}
            order="right"
            intro={true}
            background={blueBG}
          />
          <Container>
            <div className="my-32">
              <OneCol
                key={node.about_onecol.id}
                title={node.about_onecol.onecol_title}
                color={node.about_onecol.onecol_color}
                description={node.about_onecol.onecol_description}
              />
              <Grid
                container
                className="flex member-wrapper mt-20 justify-center"
              >
                <Members />
              </Grid>
            </div>
          </Container>
          <Parallax
            title="Are you a mortgage broker? <br />Partner with us."
            link="/brokers"
            button="learn more"
            image={Footer}
            order="right"
          />
        </React.Fragment>
      ))}
    </Layout>
  );
}

export const query = graphql`
  query About {
    allStrapiAbout {
      edges {
        node {
          id
          about_intro {
            id
            intro_title
            intro_description
            intro_color
          }
          about_bluesection {
            id
            Bluesection_title
            bluesection_title_description
          }
          about_onecol {
            id
            onecol_title
            onecol_description
            onecol_color
          }
        }
      }
    }
  }
`;
