import React from "react"
import { graphql, StaticQuery } from "gatsby"
import { Grid, Paper } from "@material-ui/core"

class Members extends React.Component {
  render() {
    return (
      <>
        {this.props.members.map(member => {
          return (
            <Grid item md={5} key={member.node.id}>
              <Paper elevation={3} className="p-10 flex m-3">
                {member?.node?.member_image?.publicURL && (
                  <div className="member-img pr-5">
                    <img
                      src={member?.node?.member_image?.publicURL}
                      alt={member?.node?.member_name}
                    />
                  </div>
                )}
                <div className="member-text flex flex-col justify-center">
                  <p className="text-blue-500">{member?.node?.member_name}</p>
                  <p>{member?.node?.member_role}</p>
                  <p className="member-email">
                    <a href={`mailto:${member?.node?.member_email}`}>
                      {member?.node?.member_email}
                    </a>
                  </p>
                </div>
              </Paper>
            </Grid>
          )
        })}
      </>
    )
  }
}

export default () => (
  <StaticQuery
    query={graphql`
      query {
        allStrapiMembers {
          edges {
            node {
              id
              member_name
              member_role
              member_email
              member_image {
                publicURL
              }
            }
          }
        }
      }
    `}
    render={data => <Members members={data.allStrapiMembers.edges} />}
  />
)
